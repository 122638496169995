import * as DOMPurify from 'isomorphic-dompurify';
import styles from './Animation.module.scss';
import Marker from '#images/animation/marker.png';
import Target from '#images/animation/target.svg';
import Button from '#components/ui/Button/index';
import { prepareUrl } from '#utils/prepareUrl';
import i18next from 'i18next';
import { FinalStepProps } from '#types/MainAnimation';
import { initAnimation, resetAnimationGlob } from '#store/initAnimation';
import { loadingButton } from '#store/locate';

const FinalStep = ({
  animationType,
  finalStepRef,
  finalStepData,
  phone,
  boxAnimate,
}: FinalStepProps) => {
  const lang = i18next.language;
  const preparedLink = prepareUrl('/email', lang);
  const isIpTracker = animationType === 'ipTracker';

  const getIconType = (animationType: string) => {
    return (
      <img
        width="84"
        height="84"
        src={animationType === 'lp' ? Target.src : Marker.src}
        alt="Marker img"
      />
    );
  };

  const handleClick = (preparedLink: string) => {
    initAnimation.set(false);
    resetAnimationGlob.set(true);
    loadingButton.set(false);
    window.location.assign(preparedLink);
  };

  return (
    <section className={`${styles.located} ${styles[animationType]}`} ref={finalStepRef}>
      <div className={styles.animationWrapper}>
        <div
          className={`${styles.boxLocated} ${styles[animationType]} ${boxAnimate && styles.animate}`}
        >
          <div className={`${styles.boxIconLocated} ${styles[animationType]}`}>
            {getIconType(animationType)}
          </div>
          <p
            className={`${styles.boxTextLocated} ${styles[animationType]}`}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalStepData.located) }}
          />
          <div className={`${styles.boxPhone} ${styles[animationType]}`}>{phone}</div>
          <p className={`${styles.boxLocation} ${styles[animationType]}`}>{finalStepData.gps}</p>
          {isIpTracker && (
            <p className={`${styles.boxCreate} ${styles[animationType]}`}>{finalStepData.create}</p>
          )}
          <Button
            text={finalStepData.button}
            styleType="orange"
            customStyle={animationType}
            eventName="Locate Result"
            onClick={() => handleClick(preparedLink)}
          />
          {!isIpTracker && (
            <p className={`${styles.boxCreate} ${styles[animationType]}`}>{finalStepData.create}</p>
          )}
        </div>
      </div>
    </section>
  );
};
export default FinalStep;
